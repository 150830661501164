import "./header.css";
import logo from "../../assets/images/devfest-logo.svg";

const Header = () => {
  return (
    <header className="header">
      <div className="header-top">
        <img className="header-logo" src={logo} alt="Logo" />
      </div>
      <div className="header-ctas">
        <a
          className="header-cta"
          href="https://gdg.community.dev/events/details/google-gdg-eldoret-presents-gdg-devfest-rift-valley-2022/"
          target="_blank"
          rel="noopener noreferrer">
          RSVP
        </a>
        <a
          className="header-cta"
          href="https://sessionize.com/gdg-devfest-rift-valley-22"
          target="_blank"
          rel="noopener noreferrer">
          Speak
        </a>
      </div>
    </header>
  );
};

export default Header;
