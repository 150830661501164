import "./home.css";
import speaker from "../../assets/images/speakers/speaker.png";
import paula from "../../assets/images/speakers/paularerimoi.jpg";
import o_odinga from "../../assets/images/speakers/o_odinga.jpg";
import v_mutai from "../../assets/images/speakers/v_mutai.jpg";
import f_bada from "../../assets/images/speakers/f_bada.jpg";
import b_mugweru from "../../assets/images/speakers/b_mugweru.jpg";
import a_ouma from "../../assets/images/speakers/a_ouma.png";
import a_kiche from "../../assets/images/speakers/a_kiche.jpeg";
import b_kinyaa from "../../assets/images/speakers/b_kinyaa.jpg";
import e_maye from "../../assets/images/speakers/e_maye.png";
import w_maina from "../../assets/images/speakers/w_maina.jpg";
import w_gakuo from "../../assets/images/speakers/w_gakuo.png";
import e_allela from "../../assets/images/speakers/e_allela.jpeg";
import roselida from "../../assets/images/speakers/roselida.jpg";
import w_harun from "../../assets/images/speakers/w_harun.jpeg";

const Home = () => {
  return (
    <section className="home">
      <section className="home-banner"></section>
      <section className="home-intro">
        <span className="home-intro-date">December 03, 2022</span>
        <div className="home-intro-title">DevFest Rift Valley</div>
        <div className="home-intro-description">
          {" "}
          Whether through hands-on learning experiences, technical talks
          delivered in local languages by experts, or by simply meeting fellow
          local developers, DevFest attendees learn how to build together and
          innovate on Google's developer tools{" "}
        </div>
      </section>
      <section className="home-schedule">
        <div className="home-tab-nav">
          <div className="home-tab-nav-item">Day 1</div>
          <div className="home-tab-nav-item">Day 2</div>
        </div>
        <section className="home-tab-content">
          <div className="home-tab-content-item">
            <div className="home-tab-content-item-title">December 03, 2022</div>
            <div className="home-tab-content-item-btns">
              <button className="home-tab-content-item-btn">Morning</button>
              <button className="home-tab-content-item-btn">Afternoon</button>
              <button className="home-tab-content-item-btn">Evening</button>
            </div>
          </div>
          <section className="schedule">
            {[
              {
                id: 1,
                track_name: "Track 1",
                track_description: "Track 1 Description",
                track_time: "9:00 AM - 10:00 AM",
                sessions: [
                  {
                    id: 1,
                    time: "9:00 AM - 10:00 AM",
                    title: "Registration",
                    speakers: [],
                    description:
                      "Registration for the event will be open from 9:00 AM to 10:00 AM. Please make sure you collect your attendee badge.",
                  },
                ],
              },

              {
                id: 2,
                track_name: "Track 2",
                track_description: "Track 2 Description",
                track_time: "10:00 AM - 11:00 AM",
                sessions: [
                  {
                    id: 1,
                    time: "10:00 AM - 10:30 AM",
                    title: "Welcome Keynote",
                    speakers: [
                      {
                        name: "Maye Edwin",
                        image: e_maye,
                        role: "Software Engineer, Host",
                      },
                      {
                        name: "Paula Rerimoi",
                        image: paula,
                        role: "Program Manager, Host",
                      },
                    ],
                    description:
                      "Join us for a day of learning, networking, and fun!",
                  },
                ],
              },
              {
                id: 3,
                track_name: "Track 3",
                track_description: "Track 3 Description",
                track_time: "10:35 AM - 11:00 AM",
                sessions: [
                  {
                    id: 1,
                    time: "10:35 AM - 11:00 AM",
                    title: "Google Developers Keynote",
                    speakers: [
                      {
                        name: "Allela Eunice",
                        image: e_allela,
                        role: "Community Manager, Google",
                      },
                    ],
                    description:
                      "Keynote about Google developer technologies, resources and/or community programs",
                  },
                ],
              },
              {
                id: 4,
                track_name: "Track 4",
                track_description: "Track 4 Description",
                track_time: "11:05 AM - 11:45 AM",
                sessions: [
                  {
                    id: 1,
                    time: "11:05 AM - 11: 45 AM",
                    title:
                      "Angular without Modules - The State of Standalone Components",
                    speakers: [
                      {
                        name: "Maina Wycliffe",
                        image: w_maina,
                        role: "Google Developer Expert, Angular",
                      },
                    ],
                    description:
                      "In this talk, we will look into Angular's Standalone Components, new APIs introduced since the initial release, best practices, and third-party packages and tools state",
                  },
                  {
                    id: 2,
                    time: "11:05 AM - 11: 45 AM",
                    title: "Building an adaptive UI with Jetpack Compose",
                    speakers: [
                      {
                        name: "Ferdinand Bada",
                        image: f_bada,
                        role: "Android Engineer, Payoneer",
                      },
                    ],
                    description:
                      "How to build an adaptive UI with Jetpack Compose - How to use Jetpack Compose in your existing project - Testing Jetpack Compose UI",
                  },
                ],
              },
              {
                id: 5,
                track_name: "Track 5",
                track_description: "Track 5 Description",
                track_time: "11:50 AM - 12:30 PM",
                sessions: [
                  {
                    id: 1,
                    time: "11:50 AM - 12:30 PM",
                    title: "Building a Career in Technical Writing",
                    speakers: [
                      {
                        name: "Rose Akoth",
                        image: roselida,
                        role: "Support Specialist, Storyblok",
                      },
                    ],
                    description:
                      "Every Engineer is also a Writer. Let's dive deep into the world of technical writing, what it entails, best practices, and how you can build a career out of it",
                  },
                  {
                    id: 2,
                    time: "11:50 AM - 12:30 PM",
                    title: "Lazy Layouts in Jetpack Compose",
                    speakers: [
                      {
                        name: "Kinya Beatrice",
                        image: b_kinyaa,
                        role: "Android Engineer, Kyosk",
                      },
                    ],
                    description:
                      "In this talk, we’ll go through implementing lazy lists and grids in Jetpack Compose, adding items, and animating lists content. We’ll also look at handling state on lazy layouts. Finally, we’ll look at optimization tips and performance concerns with lazy layouts.",
                  },
                ],
              },
              {
                id: 6,
                track_name: "Track 6",
                track_description: "Track 6 Description",
                track_time: "12:35 PM - 1:15 PM",
                sessions: [
                  {
                    id: 1,
                    time: "12:35 PM - 1:00 PM",
                    title: "Ask Me Anything - With the Experts - Android",
                    speakers: [
                      {
                        name: "Wangereka Harun",
                        image: w_harun,
                        role: "Google Developer Expert, Android",
                      },
                      {
                        name: "Ferdinand Bada",
                        image: f_bada,
                        role: "Android Engineer, Payoneer",
                      },
                    ],
                    description:
                      "Get your questions answered by the experts, anything Android related.",
                  },
                  {
                    id: 2,
                    time: "12:35 PM - 1:00 PM",
                    title: "Ask Me Anything - With the Experts - Web",
                    speakers: [
                      {
                        name: "Maina Wycliffe",
                        image: w_maina,
                        role: "Google Developer Expert, Angular",
                      },
                      {
                        name: "Maye Edwin",
                        image: e_maye,
                        role: "Google Developer Expert, Web",
                      },
                    ],
                    description:
                      "Get your questions answered by the experts, anything Web related.",
                  },
                ],
              },
              {
                id: 7,
                track_name: "Track 7",
                track_description: "Track 7 Description",
                track_time: "1:20 PM - 2:00 PM",
                sessions: [
                  {
                    id: 1,
                    time: "1:20 PM - 2:00 PM",
                    title: "Lunch Break - Sponsored by Google",
                    speakers: [],
                    description:
                      "Lunch will be served from 1:20 PM to 2:00 PM. Please make sure you collect your lunch from the food court.",
                  },
                ],
              },
              {
                id: 8,
                track_name: "Track 8",
                track_description: "Track 8 Description",
                track_time: "2:05 PM - 2:45 PM",
                sessions: [
                  {
                    id: 1,
                    time: "2:05 PM - 2:45 PM",
                    title: "Internationalization in Angular (I18n)",
                    speakers: [
                      {
                        name: "Gakuo Wayne",
                        image: w_gakuo,
                        role: "Google Developer Expert, Angular",
                      },
                    ],
                    description:
                      "In this session, I will be talking about how to localize your Angular application to a language of your choice.",
                  },
                  {
                    id: 2,
                    time: "2:05 PM - 2:45 PM",
                    title: "Everything APIs",
                    speakers: [
                      {
                        name: "Otieno David Odinga",
                        image: o_odinga,
                        role: "Integration and Service Development Planning Engineer, Safaricom PLC",
                      },
                    ],
                    description:
                      "In this session, my aim is to talk about APIs. In particular, will narrow down to microservice based APIS",
                  },
                ],
              },
              {
                id: 9,
                track_name: "Track 9",
                track_description: "Track 9 Description",
                track_time: "2:50 PM - 3:30 PM",
                sessions: [
                  {
                    id: 1,
                    time: "2:50 PM - 3:30 PM",
                    title: "Common RXJS Patterns in Angular",
                    speakers: [
                      {
                        name: "Joy Linda",
                        image: speaker,
                        role: "Software Engineer, Angular",
                      },
                    ],
                    description:
                      "A overview guide in reactive programing composing data streams, fetching data, interaction of the user with the system and API interactions",
                  },
                  {
                    id: 2,
                    time: "2:50 PM - 3:30 PM",
                    title: "SQL for Machine Learning in BigQuery",
                    speakers: [
                      {
                        name: "Atieno Ouma",
                        image: a_ouma,
                        role: "Backend Developer, Juubix ",
                      },
                    ],
                    description:
                      "Hands on demo of how to use the BigQuery ML to create, execute and serve ML models directly is BigQuery using just standard SQL queries",
                  },
                ],
              },
              {
                id: 10,
                track_name: "Track 10",
                track_description: "Track 10 Description",
                track_time: "3:35 PM - 4:15 PM",
                sessions: [
                  {
                    id: 1,
                    time: "3:35 PM - 4:15 PM",
                    title: "Firebase 101, Introduction and Overview",
                    speakers: [
                      {
                        name: "Mutai Victoria",
                        image: v_mutai,
                        role: "Software Engineer, Supplier Intelligence Specialist, Assent Kenya",
                      },
                    ],
                    description:
                      "An introduction to firebase and its products with a main focus on cloud messaging",
                  },
                ],
              },
              {
                id: 11,
                track_name: "Track 11",
                track_description: "Track 11 Description",
                track_time: "4:20 PM - 5.00 PM",
                sessions: [
                  {
                    id: 1,
                    track_time: "4:20 PM - 5.00 PM",
                    title: "Goodbyes & Evening Break - Sponsored by Google",
                    speakers: [
                      {
                        name: "Maye Edwin",
                        image: e_maye,
                        role: "GDG Eldoret Lead, Google Developer Expert, Web",
                      },
                    ],
                    description:
                      "Goodbyes & Evening Break, Tea & Snacks - Sponsored by Google",
                  },
                ],
              },
            ].map((item) => {
              return (
                <div className="schedule-track">
                  <div className="schedule-track-title">
                    {item.track_time} / {item.track_name}
                  </div>
                  <div className="schedule-track-sessions">
                    {item.sessions.map((session) => (
                      <div className="schedule-item">
                        <div className="schedule-info">
                          <div className="schedule-info-title">
                            {session.title}
                          </div>
                          <div className="schedule-info-description">
                            {session.description}
                          </div>
                          <div className="schedule-info-speakers">
                            {session.speakers.map((speaker) => (
                              <div className="schedule-info-speaker">
                                <img
                                  className="schedule-info-speaker-avatar"
                                  src={speaker.image}
                                  alt="Speaker"
                                />
                                <div>
                                  <div className="schedule-info-speaker-name">
                                    {speaker.name}
                                  </div>
                                  <div className="schedule-info-speaker-role">
                                    {speaker.role}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </section>
        </section>
      </section>
      <footer className="home-footer">
        <div className="home-footer-links">
          <a href="https://gdg.community.dev/events/details/google-gdg-eldoret-presents-gdg-devfest-rift-valley-2022/">
            RSVP
          </a>
          <a href="https://sessionize.com/gdg-devfest-rift-valley-22">Speak</a>
        </div>
      </footer>
    </section>
  );
};

export default Home;
