import { Header } from "./components";
import { Home } from "./pages";

function App() {
  return (
    <section
      style={{
        padding: 0,
      }}>
      <Header />
      <Home />
    </section>
  );
}

export default App;
